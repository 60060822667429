/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "function-to-convert-string-to-vector-of-characters",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function-to-convert-string-to-vector-of-characters",
    "aria-label": "function to convert string to vector of characters permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function to convert String to Vector of characters"), "\n", React.createElement(_components.p, null, "for competitive programming and more in Rust"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "For many applications, especially for many questions in competitive programming, ability to treat string like a vector or array of characters becomes immensely important.\nIn many questions, you have to take input as a string and then perform certain operations on it like a vector."), "\n", React.createElement(_components.p, null, "In many languages like C, C++ etc. you can easily index strings using square brackets [], just like vectors or arrays."), "\n", React.createElement(_components.p, null, "In Rust, however, you can not index strings using square brackets, due to ", React.createElement(_components.strong, null, "UTF-8 encoding"), ". For example, the given code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "println!(\"{}\", string1[0]);\n")), "\n", React.createElement(_components.p, null, "will give error"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "error[E0277]: the type `str` cannot be indexed by `{integer}`\n")), "\n", React.createElement(_components.p, null, "or"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "error[E0277]: the type `str` cannot be indexed by `usize`\n")), "\n", React.createElement(_components.p, null, "These operations are used very frequently in various applications, including competitive programming."), "\n", React.createElement(_components.p, null, "So, if you are doing competitive programming in Rust, you should ", React.createElement(_components.strong, null, "include a function in your template"), " to take string as input, and return vector of chars, for easy access."), "\n", React.createElement(_components.h2, {
    id: "why-rust-do-this",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-rust-do-this",
    "aria-label": "why rust do this permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Rust do this"), "\n", React.createElement(_components.p, null, "The simple answer is to save space!"), "\n", React.createElement(_components.p, null, "Actually, Rust implements Unicode with UTF-8 encoding by default. UTF-8 tends to reduce the memory space occupied by string. But now, each character is of different length.\nSo, it is impossible to get any character by index by simply going to that block, like we did in C / C++, because we can not simply guarantee how much characters are there in between."), "\n", React.createElement(_components.p, null, "For example, ", React.createElement(_components.code, null, "a"), " is encoded as ", React.createElement(_components.code, null, "61"), " and ", React.createElement(_components.code, null, "©"), " ( copyright sign ) is encoded as ", React.createElement(_components.code, null, "C2 A9"), " in unicode. ( These are hexadecimal code )"), "\n", React.createElement(_components.p, null, "So, if we have a string ", React.createElement(_components.code, null, "a©a"), " = ", React.createElement(_components.code, null, "61 C2 A9 61"), " , and try to access string[2] by incrementing 2 bytes in base address, it will return ", React.createElement(_components.code, null, "A9"), " hexadecimal, which is invalid character."), "\n", React.createElement(_components.p, null, "So, we have to traverse whole string and move only after checking unique prefixes."), "\n", React.createElement(_components.h2, {
    id: "the-charsnth-method-and-tle",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-charsnth-method-and-tle",
    "aria-label": "the charsnth method and tle permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The ", React.createElement(_components.code, null, ".chars().nth()"), " method and TLE"), "\n", React.createElement(_components.p, null, "Rust has a method called ", React.createElement(_components.code, null, ".chars()"), " , that is used to access string as chars. It takes ", React.createElement(_components.strong, null, "O( n )"), " time complexity, because it has to decode the complete string."), "\n", React.createElement(_components.p, null, "Now, if we use, ", React.createElement(_components.code, null, ".chars().nth(i).unwrap()"), " , it will run each time with ", React.createElement(_components.strong, null, "O( n )"), " time complexity, which is very inefficient. Let us see a program using this"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn main() {\n    let string1 = \"Hello World\";\n    println!(\"{}\", string1.chars().nth(0).unwrap());\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "H"), "\n"), "\n", React.createElement(_components.p, null, "As seen above, it takes ", React.createElement(_components.strong, null, "O( n )"), " Time Complexity for accessing character each time. Hence, it results in TLE in competitive programming very often. We should be able to access each character in ", React.createElement(_components.strong, null, "O( 1 )"), " or constant time complexity for each subsequent operations."), "\n", React.createElement(_components.h2, {
    id: "converting-string-to-vector-of-characters",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#converting-string-to-vector-of-characters",
    "aria-label": "converting string to vector of characters permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Converting String to Vector of Characters"), "\n", React.createElement(_components.p, null, "As seen above, if we use ", React.createElement(_components.code, null, ".chars().nth().unwrap()"), " method, it will take ", React.createElement(_components.strong, null, "O( N )"), " or linear time complexity ", React.createElement(_components.strong, null, "for each operation"), ". So, let's say we have to print all the characters of the string. It will take ", React.createElement(_components.strong, null, "O( N ", React.createElement("sup", null, "2"), " )"), " or quadratic time complexity."), "\n", React.createElement(_components.p, null, "But we can access each element of Vector using ", React.createElement(_components.strong, null, "O( 1 )"), " or constant time complexity. So, printing all characters can be easily done in ", React.createElement(_components.strong, null, "O( N )"), " or Linear time complexity. We can convert string to vector of characters using ", React.createElement(_components.strong, null, React.createElement(_components.code, null, ".collect()")), " method."), "\n", React.createElement(_components.p, null, "Program to demonstrate this"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn main() {\n    let string1 = \"Hello World\";\n    let characters:Vec<char> = string1.chars().collect();\n    println!(\"{:?}\", characters);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "['H', 'e', 'l', 'l', 'o', ' ', 'W', 'o', 'r', 'l', 'd']"), "\n"), "\n", React.createElement(_components.h2, {
    id: "function-with-input",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function-with-input",
    "aria-label": "function with input permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function with input"), "\n", React.createElement(_components.p, null, "Now, in competitive programming as well as other string manipulation programmes, most of the programmers will want a function, that will take string input and automatically convert it to vector and return vector of characters."), "\n", React.createElement(_components.p, null, "Function for this is"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io;\n\nfn take_string() -> Vec<char> {\n    // Initiate input variable\n    let mut input = String::new();\n\n    // Read into input\n    io::stdin().read_line(&mut input).unwrap();\n\n    // Convert input to vector of characters\n    let vec:Vec<char> = input.trim().chars().collect();\n\n    // Return vector\n    return vec;\n}\n")), "\n", React.createElement(_components.p, null, "With Driver code, this looks like"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io;\n\nfn take_string() -> Vec<char> {\n    // Initiate input variable\n    let mut input = String::new();\n\n    // Read into input\n    io::stdin().read_line(&mut input).unwrap();\n\n    // Convert input to vector of characters\n    let vec:Vec<char> = input.trim().chars().collect();\n\n    // Return vector\n    return vec;\n}\n\n// Driver code\nfn main() {\n    let str1 = take_string();\n    println!(\"{:?}\", str1);\n    println!(\"First and last characters : {} {}\", str1[0], str1[str1.len()-1]);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "This is rust programming"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "['T', 'h', 'i', 's', ' ', 'i', 's', ' ', 'r', 'u', 's', 't', ' ', 'p', 'r', 'o', 'g', 'r', 'a', 'm', 'm', 'i', 'n', 'g'] ", React.createElement("br"), "\nFirst and last characters : T g"), "\n"), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "For many applications, especially for many questions in competitive programming, it is very handy to have a function to input a string as a vector of characters.\nIn this article, we made such function to take input string from user and return Vector of characters."), "\n", React.createElement(_components.p, null, "Here is function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io;\n\nfn take_string() -> Vec<char> {\n    let mut input = String::new();\n    io::stdin().read_line(&mut input).unwrap();\n    let vec:Vec<char> = input.trim().chars().collect();\n    return vec;\n}\n")), "\n", React.createElement(_components.p, null, "This function should also be ", React.createElement(_components.strong, null, "included in your template"), ", if you are doing competitive programming in rust.", React.createElement("br"), "\nAlso, we should also be able to convert array / vector of characters back to string. We shall discuss that in next article."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Function to convert String to Vector of characters - Rust Programming",
    description: "For many applications and competitive programming, it is very handy to have a function to input a string as a vector of characters. We make such a function here using chars() method."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
